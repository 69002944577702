// npm install jquery.scrollto
import "jquery.scrollto";

import $ from "jquery";

$(function() {
    $(".bl-hero, .bl-herovideo").each(function() {
        const $bl = $(this);

        initScrollDownArrow($bl);
    });
});

function initScrollDownArrow($bl) {
	const $scrolldownarrow = $bl.find(".ctrscrolldownarrow");
	$scrolldownarrow.on("click", function() {
		let $nextElement = $bl.next();
        if($nextElement.length == 0) {
            $nextElement = $bl.closest("section").next();
        }
		//const headerHeight = $("header").outerHeight();
		$(window).scrollTo($nextElement, 500, {
			//offset: -headerHeight-50
		});
	});
}
