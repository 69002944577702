import $ from "jquery"

$(function() {
    const $menu = $("#overlaymenu");
    const $toggleButton = $("#menubutton");
    const $body = $("body");

    $toggleButton.on("click", toggleMenu);
    $menu.find(".ctrclosebtn .icon").on("click", toggleMenu);

    function toggleMenu() {
        const menuVisible = $menu.hasClass("visible");
        if(menuVisible) {
            hideMenu();
        } else {
            showMenu();
        }
    }

    function showMenu() {
        $menu.addClass("visible");
        $body.addClass("overlaymenuvisible");
    }

    function hideMenu() {
        $menu.removeClass("visible");
        $body.removeClass("overlaymenuvisible");
    }

    /*
    function enhanceHtml() {
        $menu.find("li.hassubmenu > a").append('<svg class="icon icon-menuarrowdown"><use xlink:href="#icon-menuarrowdown"></use></svg>');
    }
    enhanceHtml();
    */
});
