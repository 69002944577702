import $ from "jquery"

// npm install jquery.scrollto
import "jquery.scrollto";

//"to top" button
$(function() {
	window.setInterval(updateToTopButton, 250);
	$(window).on("load", updateToTopButton);
	
    const $totopbutton = $("#totopbutton");
	
    $totopbutton.on("click", function() {
		$(window).scrollTo(0, 500);
	});

    function updateToTopButton()
    {
        var scrolltop = $(window).scrollTop();
        //var ismobile = window.matchMedia && window.matchMedia("(max-width: 1024px)").matches;
        if(scrolltop > window.innerHeight) {
            $totopbutton.addClass("visible");
        } else {
            $totopbutton.removeClass("visible");
        }
    }    
});

